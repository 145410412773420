
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';

import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type ClusterPromotionsService from '@/modules/cluster/cluster-promotions.service';
import type ProvidersService from '@/modules/providers/providers.service';
import type HelperService from '@/modules/common/services/helper.service';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import PosDocumentFilter from '@/modules/document-filters/components/pos-document-filter.vue';
import LosDocumentFilter from '@/modules/document-filters/components/los-document-filter.vue';

@Component({
    components: {
        PosDocumentFilter,
        LosDocumentFilter,
        CustomSelect,
    },
})
export default class PromotionsActions extends Vue {
    @inject(KEY.ClusterPromotionsService) private clusterPromotionsService!: ClusterPromotionsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.HelperService) helperService!: HelperService;

    beforeMount() {
        if (!this.documentFiltersService.settings.los) {
            this.documentFiltersService.saveLos(1);
        }
    }

    get programItems() {
        const { programs, provider } = this.clusterPromotionsService;

        if (!provider) return [];

        const items = programs.map(value => ({
            value,
            name: this.$tc(`promotions.program.${value}`),
        }));

        if (!programs.includes(this.program)) {
            this.program = programs[0] || '';
        }

        return items;
    }

    get providerItems() {
        return this.providersService
            .toItemsList(this.providersService.userProviders.promotionProviders);
    }

    get provider() {
        return this.clusterPromotionsService.settings.provider;
    }

    set provider(value: string | null) {
        this.clusterPromotionsService.settings.provider = value;
    }

    get program() {
        return this.clusterPromotionsService.settings.programView;
    }

    set program(value: string) {
        this.clusterPromotionsService.settings.programView = value;
    }
}
